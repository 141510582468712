.badge {
  font-family: DINNextLTPro;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: center;
  color: $white;
  width: 76px;
  height: 19px;
  border-radius: 3px;
  line-height: normal;
}

.badge-gray {
  @extend .badge;
  background-color: $gray_1000;
}

.badge-warning {
  @extend .badge;
  @extend .fluz-gradient-warning;
}

.badge-danger {
  @extend .badge;
  @extend .fluz-gradient-danger;
}

.badge-success {
  @extend .badge;
  font-size: 14px;
  line-height: 14px;
  width: auto;
  height: 21px;
  background-color: #30da42;
}

.badge-completed-steps {
  color: $white;
  text-align: center;
  font-family: DINNextLTPro;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.4px;
  background-color: $gray_700;
  margin-right: auto;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
}
