$spacing-xxs: 5px;
$spacing-xs: 10px;
$spacing-s: 15px;
$spacing-m: 20px;
$spacing-l: 25px;
$spacing-xl: 30px;

$gap_values: (
  '0': 0rem,
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 1rem,
  '4': 1.5rem,
  '5': 3rem,
);

@each $name, $value in $gap_values {
  .gap-#{$name} {
    gap: $value;
  }
}
