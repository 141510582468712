.main-sidebar .c-sidebar {
  &.c-sidebar-fixed {
    top: 60px;
  }
}

.main-sidebar .c-main {
  padding-top: 1rem;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.main-sidebar .c-sidebar-backdrop.c-show {
  @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
    top: 60px;
    animation: fade-in 0.3s ease-in;
  }
}

.fluz-header {
  height: 60px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: $white;
  border-bottom: solid 1px $gray_200;
  width: 100%;
  padding: 0 30px 0 30px;
  z-index: 1040;
}

.fluz-sub-header {
  height: 50px;
  top: 60px;
  position: fixed;
  align-items: center;
  background-color: $white;
  border-bottom: solid 1px $gray_200;
  width: 100%;
  padding: 0 30px 0 30px;
  z-index: 1040;
  color: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start);
}

.main-sidebar .nav-tabs .nav-link {
  color: $gray_800;
  color: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start);
}

.main-sidebar .nav-tabs .nav-link.active {
  background-color: $white;
  color: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start);
}

.main-sidebar .c-sidebar-nav-link {
  @extend .p-regular;
  height: 60px;
  &:hover {
    background-image: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start) !important;
    color: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start) !important;
  }
  &:active {
    background-image: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start) !important;
    color: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start) !important;
  }
}

.main-sidebar .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active {
  background-color: $gray_700;
  color: $white;
}

.main-sidebar .c-main > .container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

.main-sidebar > .c-sidebar-footer {
  background: $halfgray_100 !important;
  padding: 0.87rem 1rem 0.87rem 1rem;
}

.main-sidebar .modal-footer {
  border-top: 0;
}

.main-sidebar .footer-actions {
  min-width: 18rem;
  max-width: 20rem;
}

.main-sidebar .card-container {
  margin-top: 150px !important;
  margin-bottom: 140px !important;
  min-height: 0 !important;
  max-width: 820px;
}

.main-sidebar .company-info-layout {
  margin: 150px 20px 150px 20px;
}

.container-default-margins {
  margin: 150px 20px 150px 20px;
}

.route-wrapper-position > div {
  position: relative !important;
}
