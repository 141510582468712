ul.pagination {
  margin-top: unset;
  margin-bottom: unset;
}
.data-table .table td {
  height: 67px;
  vertical-align: middle;
}

.data-table.table-striped tbody tr:nth-of-type(odd) {
  background-color: $halfgray_100;
}

.table {
  background-color: $white;
}

.table thead th {
  border-top: 0;
  color: $gray_800;
}

.table-row-active-sm {
  @media screen and (max-width: 767px) {
    &:active {
      background-color: $gray_100 !important;
    }
  }
}

.nav-tabs,
.nav-tabs .nav-link {
  border: 0;
}

.nav-link {
  padding: 1rem;
}
.table-min-width-sm {
  min-width: 6rem;
}
.table-min-width-md {
  min-width: 8rem;
}
.table-min-width-lg {
  min-width: 10rem;
}
.table-min-width-xl {
  min-width: 12rem;
}
.table-max-width-xs {
  max-width: 4rem;
}
.table-max-width-sm {
  max-width: 6rem;
}
.table-max-width-md {
  max-width: 8rem;
}
.table-max-width-lg {
  max-width: 10rem;
}
.table-max-width-xl {
  max-width: 12rem;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

.filter-floating-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 42px;
  left: 0;
  width: 277px;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
}

.filter-floating-container-hoverable-item {
  &:hover {
    background-color: $gray_100;
  }
}

#startDate {
  display: none;
}

#endDate {
  display: none;
}
