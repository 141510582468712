.form-control {
  height: 35px;
  border-radius: 3px;
  border: solid 1px $gray_100;
  background-color: $halfgray_100;
  font-family: DINNextLTPro;
  font-size: 17px;
  letter-spacing: -0.24px;
  color: $gray_1000;
  &:disabled {
    background-color: $gray_100;
    color: $gray_400;
  }
  &:read-only {
    background-color: $gray_100;
    color: $gray_400;
  }
}
.form-control-without-readonly {
  height: 35px;
  border-radius: 3px;
  border: solid 1px $gray_100;
  background-color: $halfgray_100;
  font-family: DINNextLTPro;
  font-size: 17px;
  letter-spacing: -0.24px;
  color: $gray_1000;
  &:disabled {
    background-color: $gray_100;
    color: $gray_400;
  }
}

.input-group-text {
  border: solid 1px $gray_100;
  background-color: $halfgray_100;
  font-family: DINNextLTPro;
  color: $gray_1000;

  &:disabled {
    background-color: $halfgray_100;
  }
  &:read-only {
    background-color: $halfgray_100;
  }
}

/** google place auto-complete */
.pac-container {
  z-index: 2147483647;
}

.pac-logo:after {
  display: none;
}

.pac-item-query {
  @extend .p-regular;
  line-height: 42px;
  padding-left: 0.5rem;
}

.pac-icon {
  display: none;
}

.DateRangePicker_picker {
  z-index: 1100 !important;
}

.DateInput_input {
  text-align: center;
  @extend .p-regular;
}

.CalendarDay__selected_span {
  background-color: $gray_900 !important; //background
  color: white !important; //text
  border-color: white !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $gray_900 !important;
  color: white !important;
  border-color: white !important;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background-color: $gray_600 !important;
  color: white !important;
  border-color: white !important;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $gray_600 !important;
  color: white !important;
  border-color: white !important;
}

.input-label {
  font-family: DINNextLTPro;
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  color: $gray_900;

  &-description {
    text-align: right;
  }
}

.custom-control-label.gray-checkbox::before {
  width: 18px;
  height: 18px;
  top: 0;
  background-color: $gray_50;
  border-color: $gray_200;
}

.custom-control-label.gray-checkbox::after {
  width: 18px;
  height: 18px;
  top: 0;
}

.custom-date-input {
  .SingleDatePicker {
    width: 0 !important;
    height: 0 !important;

    .SingleDatePickerInput {
      width: 0 !important;
      height: 0 !important;

      .DateInput__small {
        width: 0 !important;
        height: 0 !important;

        input {
          width: 0 !important;
          height: 0 !important;
          padding: 0 !important;
        }

        svg.DateInput_fang {
          display: none;
        }
      }

      .SingleDatePicker_picker {
        top: 5px !important;
      }
    }
  }
}

.date-picker-wrapper {
  .SingleDatePicker {
    width: 100%;

    .SingleDatePickerInput {
      width: 100%;
      .DateInput {
        width: 100%;
      }

      input {
        height: 35px;
        border-radius: 3px;
        border: solid 1px $gray_100;
        background-color: $halfgray_100;
        font-family: DINNextLTPro;
        font-size: 17px;
        letter-spacing: -0.24px;
        color: $gray_1000;
        width: 100%;
        text-align: left;
        &:disabled {
          background-color: $gray_100;
          color: $gray_400;
        }
        &:read-only {
          background-color: $gray_100;
          color: $gray_400;
        }
        &::placeholder {
          color: $gray_600;
        }
      }
    }
  }
}

.always-show-error {
  display: block !important;
}

.select-input {
  .fluz-select-container {
    height: 35px;
    border-radius: 3px;
    .fluz-select__control {
      height: 35px;
      min-height: 35px;
      background-color: $gray_50;
      border-color: $gray_100;
    }
    .fluz-select__control--is-disabled {
      background-color: $gray_100;
      color: $gray_400;
    }
  }
  .css-2b097c-container {
    height: 35px;

    .css-yk16xz-control {
      height: 35px;
      min-height: 35px;
      background-color: $gray_50;
      border-color: $gray_100;
    }

    .css-1pahdxg-control {
      height: 35px;
      min-height: 35px;
      box-shadow: 0 0 0 0.2rem rgba(182, 33, 255, 0.25);
      border-color: #e0a1ff;
    }

    .css-26l3qy-menu {
      z-index: 100;
    }
  }

  .css-g1d714-ValueContainer {
    padding: 0 12px;

    & > div.css-1uccc91-singleValue {
      font-size: 17px;
      color: $gray_900;
    }

    & > div.css-1wa3eu0-placeholder {
      font-size: 17px;
      color: $gray_700;
    }

    .css-b8ldur-Input {
      margin: 1px;
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    height: 33px;

    .css-1okebmr-indicatorSeparator {
      margin: 6px 0 6px 0;
      height: 21px;
    }

    .css-tlfecz-indicatorContainer {
      padding: 6px;

      & > svg {
        height: 21px;
      }
    }

    .css-1gtu0rj-indicatorContainer {
      padding: 6px;

      & > svg {
        height: 21px;
      }
    }
  }
}

.form-section-title {
  font-family: DINNextLTPro;
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  color: $gray_900;
}

.form-section-subtitle {
  font-family: DINNextLTPro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.252px;
  color: $gray_900;
}

.form-disclaimer {
  font-family: DINNextLTPro;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.236px;
}

.calendar-selection-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 28px;
  border-radius: 4px;
  border: 1px solid $gray_1000;
  color: $gray_1000;

  & .item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-family: DINNextLTPro;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.194px;
  }

  & .active {
    background-color: $gray_1000;
    color: $white;
  }
}
