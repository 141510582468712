.drawer-wrapper {
  --transition-speed: 0.3s;

  .c-sidebar-lg {
    flex: 0 0 400px;
    width: 400px;
  }
}

html:not([dir='rtl']) .drawer-wrapper .c-sidebar.c-sidebar-lg.c-sidebar-right {
  margin-right: -400px;
}

html:not([dir='rtl']) .drawer-wrapper.open .c-sidebar.c-sidebar-lg.c-sidebar-right {
  margin-right: 0px;
}

.drawer {
  z-index: 11;

  .c-sidebar-nav-link {
    padding: 1.5rem;
  }

  .c-sidebar-footer {
    background-color: $white !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 20px 1.5rem;
  }
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.drawer-wrapper.open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 10;
}
